@import "../../../styles/variables";
@import "../../../styles/mixins";
@import "../../../styles/common";

.search {
    position: relative;

    &Input {
        width:            100%;
        max-width:        400px;
        height:           36px;
        color:            $text;
        font-family:      "Open Sans", Arial, sans-serif;
        font-size:        16px;
        letter-spacing:   0.3px;
        line-height:      1;
        border:           1px solid $gray6;
        background-color: #ffffff;
        padding:          8px 0px 8px 40px;

        &:focus {
            border: 1px solid $primary;
        }

        &::placeholder {
            color:       $gray6;
            font-size:   14px;
            padding-top: 4px;
        }
    }

    &Icon {
        position:  absolute;
        left:      15px;
        top:       50%;
        transform: translateY(-50%);
        bottom:    3px;
    }
}