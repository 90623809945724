@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/common";

.container {
    padding-top: 68px;

    @media (max-width: $big_mobile) {
        padding-top: 20px;
    }
}

.grid {
    display:     flex;
    flex-wrap:   wrap;
    align-items: flex-end;
    margin:      -12px;
    max-width:   700px;

    > * {
        margin: 12px;
        width:  45%;

        @media (max-width: $big_mobile) {
            width: 100%;
        }
    }
}

.buttonRow {
    display:         flex;
    margin-top:      24px;
    justify-content: flex-end;

    > * {
        margin-left: 12px;
    }
}

.error {
    font-size: 12px;
    color:     $error;
}

.header {
    display:         flex;
    justify-content: space-between;

    @media (max-width: $big_mobile) {
        flex-direction: column;
        align-items:    center;
    }
}
