@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/common";


.additional {
    font-size: 10px;
}
.wrapper {
    padding-top:    138px;
    position:       relative;
    padding-bottom: 150px;

    @include breakpoint($tablet_horizontal) {
        padding-top: 88px;
    }
}

.orders {
    width:          100%;
    border-left:    1px solid $gray7;
    padding-left:   40px;
    padding-bottom: 40px;

    @include breakpoint($tablet) {
        border:       none;
        padding-left: 0;
    }
}

.tests {
    width:          100%;
    padding-bottom: 40px;
    border-left:    1px solid $gray7;
    padding-left:   40px;

    @include breakpoint($tablet) {
        border:       none;
        padding-left: 0;
    }

    table {
        a {
            color: $primary;
        }
    }
}

.containerFlex {
    display:    flex;
    margin-top: 50px;

    @include breakpoint($tablet_horizontal) {
        display: block;
    }
}

.menuLink {
    color:          $primary;
    font-family:    "Open Sans", Arial, sans-serif;
    font-size:      14px;
    letter-spacing: 0.3px;
    position:       relative;
    padding-left:   20px;
    cursor:         pointer;
    margin-bottom:  40px;
    display:        none;

    @include breakpoint($tablet_horizontal) {
        display: block;
    }

    &:hover {
        text-decoration: underline;
    }

    &:visited {
        color: $primary;
    }

    &:before {
        content:          '';
        position:         absolute;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='14' viewBox='0 0 9 14'%3E%3Cg%3E%3Cg transform='rotate(-270 4.5 7)'%3E%3Cpath fill='%230af' d='M9.472 3L4.501 7.944-.472 3-2 4.522 4.5 11 11 4.522z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        left:             0;
        top:              50%;
        transform:        translateY(-50%);
        width:            9px;
        height:           14px;
    }
}

.menuLinkBack {
    display:       block;
    margin-bottom: 91px;

    @include breakpoint($tablet_horizontal) {
        margin-bottom: 34px;

        &Mobile {
            display: none;
        }
    }
}

.tableWrap {
    width:     100%;
    max-width: 900px;
}

.sort {
    &Icon {
        path {
            fill: $gray5;
        }

        &Active {
            path {
                fill: $primary;
            }
        }
    }

    &Block {
        top:              0;
        z-index:          100;
        position:         sticky;
        background-color: #fff;
        color:            $text;
        font-size:        14px;
        font-family:      "Open Sans", Arial, sans-serif;
        font-weight:      600;
        line-height:      1.5rem;
        letter-spacing:   0.3px;
        display:          table-cell;
        padding:          16px 0 16px 14px;
        text-align:       left;
        box-sizing:       border-box;
        width:            100%;
        outline:          0;
        vertical-align:   middle;
        white-space:      nowrap;

        span {
            padding-left: 8px;
        }
    }
}


.mobileOrders {
    &Item {
        border-top: 1px solid $gray7;
        width:      100%;
        padding:    12px;

        &:last-child {
            border-bottom: 1px solid $gray7;
        }

        .btnPrimary {
            margin-top: 13px;
        }
    }

    &Title {
        display:     flex;
        align-items: center;
    }

    &Title,
    &Text {
        color:          $text;
        font-family:    "Open Sans", Arial, sans-serif;
        font-size:      14px;
        line-height:    1.3;
        font-weight:    600;
        letter-spacing: 0.3px;
        padding:        2px 0;
    }

    &Text {
        font-weight: 400;
        margin-left: 2px;
    }

    .btnPrimary {
        width:     100%;
        max-width: 288px;

        @include breakpoint($mobile) {
            max-width: 100%;
        }
    }

    .mobileOrdersName {
        color:          $text;
        font-size:      20px;
        font-weight:    600;
        letter-spacing: 0.3px;
        line-height:    1.5;
        margin-bottom:  19px;
    }
}

.mobileTests {
    &Item {
        border-top: 1px solid $gray7;
        width:      100%;
        padding:    12px 0 17px;
        position:   relative;

        &:last-child {
            border-bottom: 1px solid $gray7;
        }

        .btnPrimary {
            margin-top: 13px;
        }
    }

    &Comments {
        position: absolute;
        right:    16px;
        top:      16px;
    }

    &Title,
    &Text {
        color:          $text;
        font-family:    "Open Sans", Arial, sans-serif;
        font-size:      14px;
        font-weight:    600;
        letter-spacing: 0.3px;
        padding:        4px 0px;
    }

    &Text {
        font-weight: 400;
        margin-left: 2px;
    }


    .btnPrimary {
        width:     100%;
        max-width: 288px;

        @include breakpoint($mobile) {
            max-width: 100%;
        }
    }
}

.heading20 {
    @include breakpoint($tablet_horizontal) {
        margin-bottom: 10px;
    }
}

.ordersResultsInfo,
.testsResultsInfo {
    color:          $gray5;
    font-family:    "Open Sans", Arial, sans-serif;
    font-size:      14px;
    letter-spacing: 0.3px;
    margin-bottom:  20px;
}

.mobileTestsLink {
    color: $primary;

    &:visited {
        color: $primary;
    }
}

.sorry {
    margin-top:  20px;
    padding-top: 18px;
    border-top:  1px solid $gray7;

    &Text {
        color:          $text;
        font-family:    "Open Sans", Arial, sans-serif;
        font-size:      14px;
        letter-spacing: 0.3px;
    }
}

.btnApproveMobile {
    margin-top: 24px;
}

/*Test Details Page styles BEGIN---*/

.contentWrapper {
    justify-content: space-between;
    padding-bottom:  190px;
}

.adminSection {
    display:        flex;
    flex-direction: column;
    flex-grow:      2;
    flex-shrink:    1;
    margin-right:   20px;

    @include breakpoint($big_mobile) {
        margin-right:  0;
        margin-bottom: 33px;
    }
}

.resultsTable {
    max-width:      700px;
    flex-shrink:    4;
    padding-bottom: 0;
    margin-top:     65px;
    margin-left:    0;

    @include breakpoint($big_mobile) {
        margin-top: 0;
    }

    thead {
        tr {
            &:first-child {
                th {
                    padding-right: 28px;
                }

                .sortBlock {
                    padding-left: 0;
                }
            }

            th {
                padding: 14px 14px 0px 14px;

                &:last-child {
                    padding-right: 8px;

                    div {
                        text-align:    right;
                        padding-right: 5px;
                    }
                }
            }
        }
    }

    tbody tr {
        td {
            padding: 14px;

            &:first-child {
                text-align: left;
            }
        }
    }
}

.navigationTitle {
    @include breakpoint($tablet) {
        font-size: 20px;
    }
}

.testInfo {
    width:          100%;
    max-width:      350px;
    border-radius:  9px;
    background:     $gray8;
    display:        flex;
    flex-direction: column;
    padding:        36px 40px 40px;
    margin-top:     24px;

    @include breakpoint($big_mobile) {
        padding:    23px 24px 24px;
        margin-top: 11px;
    }


    &String {
        color:          $text;
        line-height:    1.42;
        padding-bottom: 5px;
    }

    &Bold {
        font-weight: 600;
    }

    &Btn {
        width:      100%;
        margin-top: 20px;

        @include breakpoint($big_mobile) {
            margin-top: 15px;
        }
    }
}

.comment {

    &Field {
        box-sizing:       border-box;
        width:            100%;
        max-width:        350px;
        min-height:       108px;
        color:            $text;
        background-color: #ffffff;
        border:           1px solid $gray6;
        border-radius:    0;
        font-size:        14px;
        line-height:      1.4;
        resize:           none;
        overflow:         auto;
        letter-spacing:   0.2px;
        transition:       all 0.2s ease-in-out;
        padding:          8px 12px 10px;
        margin:           40px 0px 15px;

        @include breakpoint($big_mobile) {
            margin-top: 30px;
        }
    }

    &BtnWrapper {
        max-width:       350px;
        display:         flex;
        justify-content: flex-end;
    }

    &List {
        margin-top: 40px;

        @include breakpoint($big_mobile) {
            margin-top: 30px;
        }
    }

    &Item {
        max-width:  350px;
        width:      100%;
        border-top: 1px solid $gray7;

        &Author {
            color:          $text;
            font-size:      14px;
            font-weight:    600;
            letter-spacing: 0.3px;
            line-height:    1.42;
            margin:         19px 0 1px;
        }

        &Date {
            color:          $gray5;
            font-size:      12px;
            letter-spacing: 0.3px;
            line-height:    1.5;
            text-transform: uppercase;
        }

        &Text {
            color:          $text;
            font-size:      12px;
            letter-spacing: 0.3px;
            line-height:    1.5;
            margin:         8px 0 17px;
            max-width:      100%;
            white-space:    normal;
            overflow:       auto;
        }
    }

    textarea::-webkit-input-placeholder {
        color: $gray6;
    }

    textarea:-moz-placeholder {
        color: $gray6;
    }

    textarea::-moz-placeholder {
        color: $gray6;
    }

    textarea:-ms-input-placeholder {
        color: $gray6;
    }

    &Label {
        position:  relative;
        display:   block;
        max-width: 350px;
    }

    &Counter {
        position:      absolute;
        right:         0;
        bottom:        -2px;
        font-size:     12px;
        color:         $gray4;
        margin-bottom: 2px;
    }
}

.dot {
    height:           6px;
    width:            6px;
    border-radius:    50%;
    display:          inline-block;
    background-color: #c80000;
    position:         absolute;
    top:              50%;
    transform:        translateY(-50%);
    right:            -16px;

    &Wrapper {
        position:        relative;
        margin-right:    32px;
        display:         flex;
        align-items:     center;
        justify-content: flex-end;
    }
}

.downloadLink {
    color: $primary
}

.dangerIcon {
    width:       16px;
    height:      13px;
    margin-left: 4px;
    position:    absolute;
    right:       -22px;
    top:         3px;
}

.dangerIconLeft {
    height:        13px;
    margin-bottom: -1px;
}

.markers {
    &Wrapper {
        max-width:    293px;
        padding-left: 0;
        list-style:   none;
        display:      inline-block;

        li {
            white-space: nowrap;
        }
    }

    &Item {
        display:      inline-block;
        margin-right: 3px;
        margin-top:   2px;
    }
}

.tableRow {
    &Text {
        color:          $text;
        font-size:      14px;
        font-family:    'Open Sans', sans-serif;
        font-weight:    400;
        letter-spacing: 0.3px;
    }

    &Highlighted {
        background-color: $gray8;
    }
}

.panicValueName {
    display:      inline-block;
    margin-right: 3px;
}

.legend {
    margin-bottom: 16px;
    font-size:     12px;
}


/*---Test Details Page styles END*/
