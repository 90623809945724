@import "../../styles/variables";
@import "../../styles/mixins";

.Button {
    @include buttonBaseStyles();

    &Secondary {
        background-color: $gray7;
        color:            $gray4;

        &:hover {
            background-color: lighten($gray7, 2);
        }

        &:active {
            background-color: darken($gray7, 2);
        }
    }
}
