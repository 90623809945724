@import "variables";
@import "mixins";

.heading30 {
    margin:         0 0 24px 0;
    color:          $text;
    letter-spacing: 0.3px;
    font-size:      30px;
    line-height:    1.7;
    font-weight:    600;

    @include breakpoint($tablet) {
        font-size: 24px;
    }
}

.heading20 {
    margin:         0 0 24px 0;
    color:          $text;
    letter-spacing: 0.3px;
    font-size:      20px;
    line-height:    1.7;
    font-weight:    600;

    @include breakpoint($tablet) {
        font-size: 16px;
    }
}

.heading14 {
    margin:         0 0 24px 0;
    color:          $text;
    letter-spacing: 0.3px;
    font-size:      14px;
    line-height:    1.7;
    font-weight:    600;
}