@import "../../styles/variables";
@import "../../styles/mixins";

.Input {
    //max-width:        340px;
    border:           1px solid $gray6;
    color:            $text;
    font-size:        16px;
    background-color: #fff;
    width:            100%;
    height:           36px;
    border-radius:    0;
    box-sizing:       border-box;
    margin:           0;
    padding:          9px 11px 9px;
    display:          block;

    &:focus {
        border: 1px solid $primary;
    }

    &::placeholder {
        color:       $gray6;
        font-size:   16px;
        padding-top: 2px;
    }

    &Wrapper {
        margin-bottom: 24px;
        position:      relative;

        @include breakpoint($tablet) {
            margin-bottom: 20px;
        }
    }

    &Label {
        display:        block;
        margin:         0 0 3px 0;
        color:          $text;
        letter-spacing: 0.2px;
        line-height:    1.7;
        font-weight:    600;
        text-align:     left;
    }

    &Disabled {
        pointer-events:   none;
        background-color: #fff;
        opacity:          0.5;
    }

    &IconRight {
        position: absolute;
        right:    8px;
        top:      33px;
    }

    &Iconed {
        padding-left: 32px;

        &Icon {
            position: absolute;
            top:      9px;
            left:     8px;
        }
    }

    &Clear {
        padding-right: 32px;

        &Btn {
            position: absolute;
            right:    8px;
            top:      9px;
        }
    }

    &Error {
        border: 1px solid $error;

        &Message {
            position:  absolute;
            bottom:    -19px;
            left:      4px;
            font-size: 12px;
            color:     $error;
        }
    }
}

