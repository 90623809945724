@import "../../../styles/variables";
@import "../../../styles/mixins";
@import "../../../styles/common";

.search {
    position:      relative;
    margin-top:    40px;
    margin-bottom: 17px;

    &Input {
        width:            100%;
        //max-width:        288px;
        height:           36px;
        line-height:      1;
        color:            $text;
        font-family:      "Open Sans", Arial, sans-serif;
        font-size:        16px;
        letter-spacing:   0.3px;
        border:           1px solid $gray6;
        background-color: #ffffff;
        padding-left:     40px;
        transition:       all 0.2s ease-out;

        @include breakpoint($mobile) {
            max-width: 100%;
        }

        &:focus {
            border: 1px solid $primary;
        }

        &::placeholder {
            color:       $gray6;
            font-size:   14px;
            padding-top: 4px;
        }
    }

    &Icon {
        position:  absolute;
        left:      15px;
        top:       50%;
        transform: translateY(-40%);
    }
}