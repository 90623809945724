@import "../../styles/variables";
@import "../../styles/mixins";

.Checkbox {

    visibility: hidden;
    height:     20px;
    width:      20px;
    position:   absolute;
    left:       0;

    &Wrapper {
        position:   relative;
        text-align: left;
    }

    &Fake {
        border-radius: 4px;
        box-sizing:    border-box;
        width:         18px;
        height:        18px;
        background:    transparent;
        border:        1px solid $gray6;
        position:      absolute;
        top:           -1px;
        left:          0;
        padding:       0 1px;
        cursor:        pointer;

        &Checked {
            background-color: $primary;
            border:           1px solid $primary;

            svg {
                transform: translateY(-1px);
            }
        }
    }

    &Label {
        cursor:         pointer;
        padding-left:   24px;
        margin:         0;
        color:          $text;
        letter-spacing: 0.2px;
        font-size:      0.9em;
        line-height:    1.5;
        font-weight:    400;
        display:        block;
        position:       relative;
        z-index:        1;
    }
}