@import "variables";

@mixin breakpoint($breakpoint) {
    @media screen and (max-width: $breakpoint) {
        @content;
    }
}

@mixin breakpointMin($breakpoint) {
    @media screen and (min-width: $breakpoint) {
        @content;
    }
}

@mixin appearance {
    appearance:   none;
    user-select:  none;
    touch-action: manipulation;
}

@mixin buttonBaseStyles {
    padding:          0 16px;
    height:           36px;
    font-family:      "Open Sans", sans-serif;
    font-weight:      600;
    letter-spacing:   0.3px;
    border-radius:    3px;
    background-color: $primary;
    color:            #fff;
    white-space:      nowrap;

    &:hover {
        background-color: lighten($primary, 8);
    }

    &:active {
        background-color: darken($primary, 8);
    }

    &:disabled {
        pointer-events: none;
        opacity:        0.5;
    }
}
