@import "../../styles/variables";
@import "../../styles/mixins";

.FormBtn {
    max-width: 112px;
    margin:    0 auto;
}

.Form {
    position:    relative;
    padding-top: 22px;

    &Wrapper {
        border-radius:    5px;
        box-sizing:       border-box;
        max-width:        500px;
        position:         relative;
        background-color: #ffffff;
        z-index:          2;
        margin:           100px auto auto;
        padding:          34px 80px 47px;
        text-align:       center;
        box-shadow:       0 4px 12px 1px rgba(35, 45, 55, 0.15);
        width:            500px;

        @include breakpoint($mobile) {
            margin:        0;
            box-shadow:    none;
            max-width:     100%;
            border-radius: 0;
            padding:       30px 16px 155px;
            width:         auto;
        }
    }

    &Title {
        margin:         0;
        color:          $text;
        letter-spacing: 0.2px;
        font-size:      30px;
        line-height:    1.7;
        font-weight:    600;
    }

    &Subtitle {
        margin:         20px -40px 10px;
        color:          $text;
        letter-spacing: 0.2px;
        font-size:      16px;
        line-height:    1.5;
        font-weight:    400;

        @include breakpoint($mobile) {
            white-space:  normal;
            margin-left:  -20px;
            margin-right: -20px;
        }
    }

    &Error {
        font-size:     0.85em;
        color:         $error;
        font-weight:   600;
        width:         100%;
        margin-bottom: 16px;
        display:       inline-block;
    }

    &Text {
        margin-top:     20px;
        margin-bottom:  16px;
        font-size:      0.86em;
        font-weight:    400;
        line-height:    18px;
        text-align:     left;
        letter-spacing: 0.12px;
        display:        block;

        &Link {
            display: inline;
            color:   $primary;
            padding: 0;

            &:hover {
                text-decoration: underline;
            }

            &:visited {
                color: $primary;
            }
        }
    }
}

.Bottom {
    display:         flex;
    justify-content: space-between;
    align-items:     center;
    margin-bottom:   40px;
    padding-top:     12px;

    &Link {
        font-size:       0.9em;
        font-weight:     600;
        transition:      all 0.2s ease-in-out;
        cursor:          pointer;
        text-decoration: none;
        color:           $primary;
        padding-left:    0;

        &:visited {
            color: $primary;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

.PasswordRecovery {

    max-width: 578px;
    width:     578px;

    &Bottom {
        margin-bottom: 0;
    }

    &Btn {
        width:  90px;
        margin: 0;
    }
}

.Verification {
    &Subtitle {
        max-width:    323px;
        margin-left:  auto;
        margin-right: auto;
    }
}

.ResetPassword {
    width:         553px;
    max-width:     553px;
    padding-left:  60px;
    padding-right: 60px;

    &Form {
        max-width:    340px;
        margin-left:  auto;
        margin-right: auto;
    }

    &Subtitle {
        margin-right: auto;
        margin-left:  auto;
        max-width:    340px;
    }

    &Bottom {
        margin-bottom: 0;

        &Link {
            margin-top: 32px;
            display:    block;
        }
    }

    &Btn {
        width: 90px;
    }
}
