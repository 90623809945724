@import "variables";
@import "mixins";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&display=swap');

html {
    font-family:    "Open Sans", sans-serif;
    font-size:      14px;
    font-weight:    400;
    letter-spacing: 0.3px;
    line-height:    20px;
    background:     #ffffff;
}

* {
    box-sizing: border-box;
}

button {
    background: transparent;
    outline:    none;
    border:     none;
    cursor:     pointer;
}

p {
    margin: 0;
}

a {
    color:           inherit;
    text-decoration: none;

    &:visited {
        color: inherit;
    }
}

input:focus, input:active {
    outline: none;
}

input {
    font-size:  16px;
    appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin:             0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

h1 {
    line-height: 1.2;
}

#root {
    display:         flex;
    flex-direction:  column;
    justify-content: space-between;
    min-height:      100%;
    height:          100vh;
}

body {
    overflow-x: hidden;
}

