@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/common";

.Labslip {
    &Section {
        padding-top: 70px;

        @include breakpoint($tablet) {
            padding-top: 60px;
        }
    }

    &Top {
        background-color: $gray8;
        padding: 70px 0 32px 0;

        @include breakpoint($tablet) {
            padding-top: 36px;
            padding-bottom: 20px;
        }

        &Container {
            @include breakpoint($tablet) {
                max-width: 500px;
                margin: 0 auto;
            }
        }
    }

    &Info {

        &Customer {
            margin-bottom: 10px;

            &Add {
                font-size:      12px;
                letter-spacing: 0.3px;
                line-height:    18px;
            }

            &Clear {
                position: absolute;
                right:    6px;
                top:      35px;
            }
        }

        &Wrapper {
            display:         flex;
            justify-content: flex-start;
            align-items:     flex-start;


            @include breakpoint($mid_width) {
                flex-wrap:       wrap;
                justify-content: center;
            }

            @include breakpoint($tablet) {
                max-width: 500px;
                margin: 0 auto;
            }

        }

        &Item {
            flex:         0 0 370px;
            position:     relative;
            margin-right: 24px;

            @include breakpoint($max_width) {
                flex-basis: 30%;
                min-width:  285px;
            }

            @include breakpoint($mid_width) {
                flex-basis:    340px;
                margin-left:   16px;
                margin-right:  16px;
                margin-bottom: 20px;
            }

            @include breakpoint($tablet) {
                margin-bottom: 0;
                margin-left: 0;
                margin-right: 0;
                flex-basis: auto;
                width: 100%;

                &:nth-child(2) {
                    margin-bottom: 20px;

                }
            }
        }
    }

    &CustomerPopup {

        position: relative;

        &Wrapper {
            height:        100vh;
            margin-left:   auto;
            flex-grow:     1;
            max-width:     40vw;
            overflow-y:    auto;
            padding:       70px 9% 168px 7%;
            border-radius: 0;
            animation:     slideIn 0.6s forwards;

            @include breakpoint($mobile) {
                position: fixed;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                max-width: none;
                padding: 70px 20px;
                animation: none;
               min-width: auto;
            }
        }

        &Close {
            svg {
                height: 16px;
                width:  16px;
            }

            position: absolute;
            right:    0;
            top:      -30px;
        }
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(40vw);
    }
    100% {
        transform: translateX(0);
    }
}

.formBtn {
    margin-top: 6px;
}

.SubmitPanel {
    position: sticky;
    bottom: 0;
    width: 100vw;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.08);
    z-index: 10;

    @include breakpoint($tablet) {
        height:        68px;
        padding-left:  6px;
        padding-right: 6px;
    }

    &Btn {
        max-width: 150px;
        margin:    0 10px;
    }
}

button span.hideMobile {
    @include breakpoint($mobile) {
        display: none;
    }
}

.Success {

    width:      570px;
    text-align: center;

    &Wrapper {
        min-height:      853px;
        padding-top:     141px;
        display:         flex;
        justify-content: center;

        @include breakpoint($mobile) {
            padding-top: 111px;
            min-height:  510px;
        }
    }

    &Title {
        font-size:      30px;
        font-weight:    600;
        letter-spacing: 0.3px;
        line-height:    44px;
        margin-bottom:  20px;
        margin-top:     20px;

        @include breakpoint($mobile) {
            font-size:     24px;
            line-height:   36px;
            margin-bottom: 10px;
        }
    }

    &Text {
        font-size:      16px;
        letter-spacing: 0.3px;
        line-height:    24px;
        padding-left:   50px;
        padding-right:  50px;

        @include breakpoint($tablet) {
            padding-left:  24px;
            padding-right: 24px;
        }

        @include breakpoint($mobile) {
            padding-left:   16px;
            padding-right:  16px;
            font-size:      14px;
            letter-spacing: 0.3px;
            line-height:    20px;
        }
    }

    &Link {
        color:         $primary;
        padding-left:  0;
        padding-right: 0;

        &:hover {
            text-decoration: underline;
        }

        &:visited {
            color: $primary;
        }
    }
}

.discardPopup {
    max-width:  402px;
    padding:    34px 32px 40px 40px;
    margin-top: -10%;

    @include breakpoint($mobile) {
        min-width:  auto;
        margin-top: 0;
        max-width:  288px;
        padding:    18px 24px;
    }

    &Title {
        font-size:      20px;
        font-weight:    600;
        letter-spacing: 0.4px;
        line-height:    30px;
        margin-bottom:  14px;
        margin-top:     0;
        color:          $text;
    }

    &Text {
        font-size:      14px;
        letter-spacing: 0.3px;
        line-height:    20px;
        margin-top:     0;
        color:          $text;
        margin-bottom:  24px;

        @include breakpoint($mobile) {
            margin-bottom: 20px;
        }
    }

    &Actions {
        display:         flex;
        justify-content: space-between;

        button {
            max-width: 158px;

            @include breakpoint($mobile) {
                max-width: 110px;
            }
        }
    }
}