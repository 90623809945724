@import "../../styles/variables";
@import "../../styles/mixins";

.Logo {
    &Container {

        @include breakpoint($tablet) {
            svg {
                width:  170px;
                height: 25px;

                g [class="pp_text"] {
                    display: none;
                }
            }
        }
    }
}
