@import "../../styles/variables";
@import "../../styles/mixins";


.modal {
    max-height:       calc(100vh - 100px);
    overflow:         auto;
    min-width:        400px;
    padding:          32px 40px;
    background-color: #fff;
    box-shadow:       0 0 10px 2px rgba(0, 0, 0, 0.06);
    border-radius:    6px;
    text-align:       left;

    &Overlay {
        position:         fixed;
        z-index:          1000;
        top:              0;
        left:             0;
        bottom:           0;
        right:            0;
        background-color: rgba(0, 0, 0, 0.6);
        display:          flex;
        align-items:      center;
        justify-content:  center;

        &Hide {
            display: none;
        }

        &Show {
            display: flex;
        }

        &Fullscreen {
            position: absolute;
            top:      70px;

        }


        @media screen and (max-width: $big_mobile_horizontal) {
            padding:     15% 0 0 0;
            align-items: start;
        }
    }

    &--approve {
        max-width: 400px;
    }
}
