@import "../../styles/variables";
@import "../../styles/mixins";

.spinner {
    position: fixed;
    top:      0;
    bottom:   15%;
    left:     0;
    right:    0;
    height:   55px;
    padding:  5%;
    margin:   auto;

    &Wrapper {
        background: rgba(0, 0, 0, 0.5);
        position:   fixed;
        top:        0;
        right:      0;
        bottom:     0;
        left:       0;
        opacity:    1;
        z-index:    10000;
        transition: all 0.15s ease-out;
        display:    none;

        &Mini {
            position:         static;
            height:           200px;
            width:            100%;
            background-color: transparent;

            .spinner {
                position: static;
                height:   100%;
                width:    100%;
            }
        }

        &Show {
            display: block;
        }
    }

}

.spinnerCircle {
    position: relative;
    margin:   0px auto;
    width:    55px;

    &:before {
        content:     '';
        display:     block;
        padding-top: 100%;
    }
}

.circular {
    stroke:           $primary;
    animation:        rotate 2s linear infinite;
    height:           100%;
    transform-origin: center center;
    width:            100%;
    position:         absolute;
    top:              0;
    bottom:           0;
    left:             0;
    right:            0;
    margin:           auto;
}

.path {
    stroke-dasharray:  1, 200;
    stroke-dashoffset: 0;
    animation:         dash 1.5s ease-in-out infinite;
    stroke-linecap:    round;
}

@-webkit-keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray:  1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray:  89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray:  89, 200;
        stroke-dashoffset: -124;
    }
}

@keyframes dash {
    0% {
        stroke-dasharray:  1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray:  89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray:  89, 200;
        stroke-dashoffset: -124;
    }
}

.spinnerRelative {
    position: relative;
    padding:  0 0 4%;
    height:   95px;

    @include breakpoint($tablet) {
        padding: 0 0 10%;
    }

    .spinnerCircle {
        width: 95px;
    }
}
